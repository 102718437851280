<template>
  <GreenBlock class-name="green-section--big mb-0">
    <v-container>
      <h1 class="page-title">Dieser Code ist nicht mehr gültig</h1>
      <div class="page-subtitle mb-0 max-width">
        <p>Sie haben Ihren Aktionscode bereits für eine Bestellung der eprimo Strompakete genutzt und wir arbeiten bereits mit Hochdruck an Ihrer Bestellung.</p>
        <p>Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte an unseren Kundenservice: <a class="text-decoration-underline"
                                                                                                          href="mailto:strompakete@eprimo.de">strompakete@eprimo.de</a></p>
      </div>
      <div class="green-section__content">
        <div class="green-section--left">
          <div class="page-subtitle mb-0">
            <p>Sie erreichen uns unter der Telefonnummer 0800-204011093</p>
          </div>
          <p class="fw-600">Diese Rufnummer ist aus dem deutschen Festnetz kostenlos. Unsere Service-Zeiten sind montags – freitags von 9.00 – 18.00 Uhr.</p>
          <div class="text-center-mobile">
            <a class="btn" href="https://www.eprimo.de/">eprimo.de besuchen</a>
          </div>
        </div>
        <div class="green-section--right">
          <img src="~@/assets/images/eprimo-face-3.svg" alt="eprimo-face">
        </div>
      </div>
    </v-container>
  </GreenBlock>
</template>

<script>
import GreenBlock from "@/components/GreenBlock";

export default {
  name: 'ErrorPage',
  components: {
    GreenBlock
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'login' || to.name === 'meter_ordering_login') {
      next();
    } else {
      this.$router.push({name: 'login'});
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  margin-bottom: 25px;
}

p {
  margin-bottom: 22px;
}

.fw-600 {
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: -5px;
}
</style>
